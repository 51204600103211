.main-layout {
  background-color: var(--background-alt) !important;
}

.header-layout {
  background-color: var(--background) !important;

  .input-group {
    background-color: var(--background-alt) !important;
  }
}

.footer-layout {
  background-color: var(--background-alt) !important;
}

.sidebar-layout {
  background-color: var(--background) !important;

  .sidebar-header {
    background: linear-gradient(to right, var(--accent), var(--header-alt));
  }

  .sidebar-nav {

    a {

      &:hover {
        background-color: var(--background-alt);
      }
  
      &:active {
        background-color: var(--background-alt) !important;
        
        span {
          color: var(--accent) !important;
        }
      }

      .LinksGroup_headerLinkActive__3WH0p span {
        color: var(--accent) !important;
      }
    }
  }
}

.report-col {
  max-width: 800px;
  
  .card {
    border: 1px solid var(--accent);
  }
}