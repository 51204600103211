:root {
  --background-alt: #F6FFF8;
  --background: #F0FBF2;
  --background-dark: #1C4702;
  --accent: #29A627;
  --heading: #163A00;
  --text: #4B5C49;
  --text-white: #EAF2D8;
  --border: #FBFFF3;
  --cancel: #eb3349;
  --pending: #e2d80e;
  --neutral: #275aa6;
  --header-alt: #a8ffa7;
  --table: #d4ffd4;
}

body {
  background-color: var(--background-alt);
}

.bg {
  background-color: var(--background);
}

.bg-alt {
  background-color: var(--background-alt);
}

.bg-dark {
  background-color: var(--background-dark);
}

.bg-red {
  background-color: var(--cancel);
  color: #fff;
}

.bg-green {
  background-color: var(--accent);
  color: #fff;
}

.bg-yellow {
  background-color: var(--pending);
  color: #fff;
}

.bg-blue {
  background-color: var(--neutral);
  color: #fff;
}

.color-white {
  color: var(--text-white);
}


.status {
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;

  &.pending {
    background-color: var(--pending);
  }
  
  &.approved {
    background-color: var(--accent);
  }
  
  &.draft {
    background-color: var(--text);
  }
  
  &.rejected {
    background-color: var(--cancel);
  }
}

// card-style
.card-design {
  border: 1px solid var(--accent);
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--background-alt);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    background-color: var(--accent);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    
    h4 {
      font-weight: 600;
    }
  }

  .card-body {
    padding: 25px ;
    background-color: var(--background-alt);

    input, textarea, select {
      border-radius: 5px !important;
      background-color: #fff;
      border: 1px solid var(--accent);

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(55, 165, 82, 0.25);
        border: 1px solid #fff;
      }
    }

    input {
      padding: 15px;
    }

    .custbtn {
      padding: 8px 20px;
      font-size: 16px;
    }
  }
}

table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 50px;

  .table-header th{
    background-color: var(--accent);
    color: #fff;
  }

  &.table-alt {
    .table-header-alt th{
      background-color: var(--table);
    }
  
    tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }
  
    tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }
  
    tbody td {
      background-color: var(--background);
    }
  
    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }
      
    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
}


.custbtn {

  &.btn-primary {
    background-color: var(--accent);
    color: var(--text-white);
  }
  &.btn-secondary {
    border-color: var(--accent);
    background-color: transparent;
    color: var(--accent);
  }
  &.btn-alt {
    border-color: var(--background);
    background-color: var(--background);
    color: var(--accent);
  }

  &:hover {
    &.btn-primary {
      border-color: var(--accent);
      background-color: transparent;
      color: var(--accent);
    }
    &.btn-secondary {
      background-color: var(--accent);
      color: var(--text-white);
    }
    &.btn-alt {
      border-color: var(--background);
      background-color: transparent;
      color: var(--background);
    }
  }
}

// .btn {

//   &.btn-primary {
//     background-color: var(--accent);
//     color: var(--text-white);
//   }
//   &.btn-secondary {
//     border-color: var(--accent);
//     background-color: transparent;
//     color: var(--accent);
//   }

//   &:hover {
//     &.btn-primary {
//       border-color: var(--accent);
//       background-color: transparent;
//       color: var(--accent);
//     }
//     &.btn-secondary {
//       background-color: var(--accent);
//       color: var(--text-white);
//     }
//   }
// }


// login page

.login-page {
  padding-top: 20vh;
  height: 100vh;

  .login-card {
    max-width: 550px;
    width: 100%;
    margin: auto;

    input {
      padding: 20px 15px;
    }
  
    .passGroup {
      position: relative;
  
      .passbtnContainer {
        position: absolute;
        top: 50%;
        right: 10px;
        z-index: 4;
        transform: translateY(-50%);
  
        .passbtn {
          background: transparent;
          border: none;
        }
      }
    }
    
    .btnGroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;
      gap: 10px;

      .custbtn {
        padding: 8px 30px;
        border-radius: 2px;
      }
    }
  }
}
