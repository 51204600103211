.admin-app {
  width: 100%;
}

.admin-main {
  padding-top: var(--navbar-height);
  margin-left: 200px;
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
