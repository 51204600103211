.map-container {
  z-index: 1;
}

.collapsableBirdLife {
  width: 100%;

  td {

    table {
      min-width: 90%;
      margin: 8px auto;
    }
  }

}

.export-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;

  button {
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 500;

    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}